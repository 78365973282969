// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import url("../node_modules/primeng/resources/themes/fluent-light/theme.css");
@import url("../node_modules/primeicons/primeicons.css");
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cpods-vpi-ui-primary: mat.define-palette(mat.$indigo-palette);
$cpods-vpi-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cpods-vpi-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cpods-vpi-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $cpods-vpi-ui-primary,
      accent: $cpods-vpi-ui-accent,
      warn: $cpods-vpi-ui-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cpods-vpi-ui-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

@font-face {
  font-family:Gilroy;
  src: url('./assets/fonts/Radomir Tinkov - Gilroy-Regular.otf');
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif, Gilroy;
    min-height: 100vh;
  display: grid;
  grid-template-rows:auto 1fr auto;

}

.date_enter .mat-form-field-flex {
  background: #f0f0f0;
  border: 1px solid #6e6e6e !important;
  font-size: 12px;
}

.filter_enter .mat-form-field-flex {
  background: white;
  border: 1px solid gray !important;
  font-size: 12px;
}

.paginator_en .mat-paginator-page-size-label {
  display: none;
}

.paginator_en .mat-paginator-page-size-select {
  padding-left: 21px !important;
}

.paginator_en .mat-paginator-range-label {
  // display: none !important;
}

.paginator_en .mat-paginator-outer-container {
  // display: flex;
  margin-right: 40px;
}

.paginator_en .mat-select-value {
  font-family: "Open Sans";
}

.paginator_en .mat-paginator-range-label {
  font-family: "Open Sans";
  margin: 3px 4px 0 5px !important;
}

.input_fi .mat-form-field-label {
  font-family: "Open Sans";
  font-size: 12px;
}

.toast-top-right {
  top: 66px !important;
}
.key_capabilities{
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  background: #F7F7F7 0% 0% no-repeat padding-box ;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
